<template>
  <!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
  <div id="ac9435f7">
    <div class="dal-aside">
      <img src="../assets/img-a3202d62.png"
           id="a3202d62"
           @click="clicka3202d62()" />
      <el-menu id="f1c95075"
               index="f1c95075"
               :router="true">
        <el-submenu id="ae4d404a"
                    index="ae4d404a">
          <template id="ae6d45cb"
                    slot="title">
            <div id="a4e65320">
              甲方
            </div>
          </template>
          <el-menu-item id="f7f75bb3"
                        index="Afterlanding">
            主页
          </el-menu-item>
          <el-menu-item id="a7cc19aa"
                        index="Productsunderdevelopment">
            开发中产品
          </el-menu-item>
          <el-menu-item id="cf6eac47"
                        index="Productsinoperation">
            运营中产品
          </el-menu-item>
          <el-menu-item id="ae7b0d83"
                        index="Complaintsandsuggestions">
            投诉建议
          </el-menu-item>
          <el-menu-item id="a85ff8c7"
                        index="Resourcedetails">
            资源详情
          </el-menu-item>
        </el-submenu>
        <el-submenu id="aff39595"
                    index="aff39595">
          <template id="ad0ac297"
                    slot="title">
            <div id="f6a6d553">
              销售
            </div>
          </template>
          <el-menu-item id="c0a99338"
                        index="sale">
            销售主页
          </el-menu-item>
        </el-submenu>
        <el-submenu id="ba25a2f3"
                    index="ba25a2f3">
          <template id="ccbb31b2"
                    slot="title">
            <div id="aa3de0f6">
              运营专员
            </div>
          </template>
          <el-menu-item id="feabdb07"
                        index="Contentmanagement">
            内容管理
          </el-menu-item>
          <el-menu-item id="a4577423"
                        index="channelmanagement">
            渠道管理
          </el-menu-item>
          <el-menu-item id="a7923738"
                        index="Customerlist">
            客户列表
          </el-menu-item>
          <el-menu-item id="ae69b77d"
                        index="Information">
            文章资讯
          </el-menu-item>
          <el-menu-item id="acda3625"
                        index="recruitment">
            企业招聘
          </el-menu-item>
          <el-menu-item id="a127529f"
                        index="Enterprise">
            公司简介
          </el-menu-item>
          <el-menu-item id="ada52486"
                        index="float">
            编辑悬浮窗
          </el-menu-item>
        </el-submenu>
        <el-submenu id="a5e49c58"
                    index="a5e49c58">
          <template id="a3870799"
                    slot="title">
            <div id="a7b990c0">
              产品经理
            </div>
          </template>
          <el-menu-item id="b45e0204"
                        index="productmanager">
            产品主页
          </el-menu-item>
          <el-menu-item id="cfe4dc7b"
                        index="Productmanagercommunicationproject">
            沟通中项目
          </el-menu-item>
          <el-menu-item id="a902d532"
                        index="UIdesignproject">
            设计中项目
          </el-menu-item>
          <el-menu-item id="afe1609d"
                        index="UIdevelopmentproject">
            开发中项目
          </el-menu-item>
          <el-menu-item id="f367ca89"
                        index="Productmanageroperationproject">
            运营中项目
          </el-menu-item>
          <el-menu-item id="de9fac9c"
                        index="Customerlist">
            客户列表
          </el-menu-item>
        </el-submenu>
        <el-submenu id="afc93192"
                    index="afc93192">
          <template id="aff448f6"
                    slot="title">
            <div id="adc88171">
              UI设计师
            </div>
          </template>
          <el-menu-item id="a2951665"
                        index="UIdesignproject">
            设计中项目
          </el-menu-item>
          <el-menu-item id="a99c0001"
                        index="UIdevelopmentproject">
            开发中项目
          </el-menu-item>
        </el-submenu>
        <el-submenu id="a65db403"
                    index="a65db403">
          <template id="fa7c6235"
                    slot="title">
            <div id="a6a37c52">
              项目经理
            </div>
          </template>
          <el-menu-item id="ccfb1508"
                        index="UIdesignproject">
            设计中项目
          </el-menu-item>
          <el-menu-item id="b4d6c792"
                        index="UIdevelopmentproject">
            开发中项目
          </el-menu-item>
          <el-menu-item id="ce5e0416"
                        index="Productmanageroperationproject">
            运营中项目
          </el-menu-item>
          <el-menu-item id="aa9ad6e7"
                        index="Businessunit">
            业务单元
          </el-menu-item>
        </el-submenu>
        <el-submenu id="ad1fb27f"
                    index="ad1fb27f">
          <template id="a891505f"
                    slot="title">
            <div id="be50ddc9">
              项目组成员
            </div>
          </template>
          <el-menu-item id="c53bfec6"
                        index="UIdevelopmentproject">
            开发中项目
          </el-menu-item>
          <el-menu-item id="ce152895"
                        index="Productmanageroperationproject">
            运营中项目
          </el-menu-item>
          <el-menu-item id="a414a716"
                        index="Businessunit">
            业务单元
          </el-menu-item>
        </el-submenu>
        <el-submenu id="abc8dc56"
                    index="abc8dc56">
          <template id="ae798753"
                    slot="title">
            <div id="f6039933">
              运维监控
            </div>
          </template>
          <el-menu-item id="a831a061"
                        index="Projectlist">
            项目列表
          </el-menu-item>
          <el-menu-item id="a7f45980"
                        index="Businessunit">
            业务单元
          </el-menu-item>
          <el-menu-item id="a89642ef"
                        index="Globalresources">
            全局资源
          </el-menu-item>
          <el-menu-item id="dc5457ac"
                        index="Physicalresourcemonitoring">
            物理资源监控
          </el-menu-item>
          <el-menu-item id="a71ae2eb"
                        index="Backupmonitoring">
            备份监控
          </el-menu-item>
          <el-menu-item id="bccceec2"
                        index="Unitsubmissionmonitoring">
            单元提交监控
          </el-menu-item>
          <el-menu-item id="a94534ec"
                        index="Logmonitoring">
            日志监控
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div class="dal-rtv">
      <router-view>
      </router-view>
    </div>
  </div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default {
  data () {
    return {
      //在此注释下方添加自定义变量
    }
  },
  mounted () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    async clicka3202d62 () {
      this.$router.push('Index')
    },
    async clickf7f75bb3 () {
      this.$router.push('Afterlanding')
    },
    async clicka7cc19aa () {
      this.$router.push('Productsunderdevelopment')
    },
    async clickcf6eac47 () {
      this.$router.push('Productsinoperation')
    },
    async clickae7b0d83 () {
      this.$router.push('Complaintsandsuggestions')
    },
    async clicka85ff8c7 () {
      this.$router.push('Resourcedetails')
    },
    async clickc0a99338 () {
      this.$router.push('sale')
    },
    async clickfeabdb07 () {
      this.$router.push('Contentmanagement')
    },
    async clicka4577423 () {
      this.$router.push('channelmanagement')
    },
    async clicka7923738 () {
      this.$router.push('Customerlist')
    },
    async clickae69b77d () {
      this.$router.push('Information')
    },
    async clickacda3625 () {
      this.$router.push('recruitment')
    },
    async clicka127529f () {
      this.$router.push('Enterprise')
    },
    async clickada52486 () {
      this.$router.push('float')
    },
    async clickb45e0204 () {
      this.$router.push('productmanager')
    },
    async clickcfe4dc7b () {
      this.$router.push('Productmanagercommunicationproject')
    },
    async clicka902d532 () {
      this.$router.push('UIdesignproject')
    },
    async clickafe1609d () {
      this.$router.push('UIdevelopmentproject')
    },
    async clickf367ca89 () {
      this.$router.push('Productmanageroperationproject')
    },
    async clickde9fac9c () {
      this.$router.push('Customerlist')
    },
    async clicka2951665 () {
      this.$router.push('UIdesignproject')
    },
    async clicka99c0001 () {
      this.$router.push('UIdevelopmentproject')
    },
    async clickccfb1508 () {
      this.$router.push('UIdesignproject')
    },
    async clickb4d6c792 () {
      this.$router.push('UIdevelopmentproject')
    },
    async clickce5e0416 () {
      this.$router.push('Productmanageroperationproject')
    },
    async clickaa9ad6e7 () {
      this.$router.push('Businessunit')
    },
    async clickc53bfec6 () {
      this.$router.push('UIdevelopmentproject')
    },
    async clickce152895 () {
      this.$router.push('Productmanageroperationproject')
    },
    async clicka414a716 () {
      this.$router.push('Businessunit')
    },
    async clicka831a061 () {
      this.$router.push('Projectlist')
    },
    async clicka7f45980 () {
      this.$router.push('Businessunit')
    },
    async clicka89642ef () {
      this.$router.push('Globalresources')
    },
    async clickdc5457ac () {
      this.$router.push('Physicalresourcemonitoring')
    },
    async clicka71ae2eb () {
      this.$router.push('Backupmonitoring')
    },
    async clickbccceec2 () {
      this.$router.push('Unitsubmissionmonitoring')
    },
    async clicka94534ec () {
      this.$router.push('Logmonitoring')
    },
    //在此注释下方添加自定义函数或事件
    async clickabf798ae () {
      this.$router.push('sale')
    },
    async clickaabd25f5 () {
      this.$router.push('Contentmanagement')
    },
    async clickc7e37ba7 () {
      this.$router.push('channelmanagement')
    },
    async clicka25babed () {
      this.$router.push('Customerlist')
    },
    async clickd9e4cf9a () {
      this.$router.push('productmanager')
    },
    async clicka58a942e () {
      this.$router.push('Productmanagercommunicationproject')
    },
    async clicka5e647ee () {
      this.$router.push('UIdesignproject')
    },
    async clickec0852db () {
      this.$router.push('UIdevelopmentproject')
    },
    async clickaeeda455 () {
      this.$router.push('Productmanageroperationproject')
    },
    async clicka38281eb () {
      this.$router.push('Customerlist')
    },
    async clickebd9ce6e () {
      this.$router.push('UIdesignproject')
    },
    async clickc156595f () {
      this.$router.push('UIdevelopmentproject')
    },
    async clickab06e7f3 () {
      this.$router.push('UIdesignproject')
    },
    async clicka6e5b2c4 () {
      this.$router.push('UIdevelopmentproject')
    },
    async clicka09990c3 () {
      this.$router.push('Productmanageroperationproject')
    },
    async clickac51710e () {
      this.$router.push('Businessunit')
    },
    async clicka8194994 () {
      this.$router.push('UIdevelopmentproject')
    },
    async clicka6a8280f () {
      this.$router.push('Productmanageroperationproject')
    },
    async clicka1be5760 () {
      this.$router.push('Businessunit')
    },
    async clickadf4aad3 () {
      this.$router.push('Projectlist')
    },
    async clickab3d4dcd () {
      this.$router.push('Businessunit')
    },
    async clicka9bb650d () {
      this.$router.push('Globalresources')
    },
    async clickf5bc8d1a () {
      this.$router.push('Physicalresourcemonitoring')
    },
    async clickae7552fd () {
      this.$router.push('Backupmonitoring')
    },
    async clickaa1c1233 () {
      this.$router.push('Unitsubmissionmonitoring')
    },
    async clicka8a7ad73 () {
      this.$router.push('Logmonitoring')
    }
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#ac9435f7 {
  display: flex;
}
#ac9435f7 .dal-aside {
  width: 271px;
  background-color: #141620;
}
#ac9435f7 .dal-rtv {
  flex: 1;
  position: relative;
}
#a3202d62 {
  width: 185px;
  height: 43px;
  padding-left: 43px;
  padding-top: 60px;
}
#a3202d62 {
  cursor: pointer;
}
#f1c95075 {
  width: 270px;
  margin-top: 60px;
  margin-left: 1px;
  margin-bottom: 2076px;
}
#f1c95075 {
  background-color: rgb(20, 22, 32, 1);
  border-radius: 0px 0px 0px 0px;
}
#f1c95075 i {
  display: none;
}
#f1c95075 .el-menu {
  background-color: #141620;
}
#ae4d404a {
  width: 266px;
  margin-left: 3px;
}
#ae4d404a .el-menu {
  margin-bottom: 30px;
  background-color: #f6f8fc !important;
  border-radius: 10px 0px 0px 10px;
}
#ae6d45cb {
  width: 266px;
}
#ae6d45cb {
  background-color: rgb(201, 23, 31, 1);
  border-radius: 10px 0px 10px 0px;
}
#ae4d404a .el-submenu__title {
  width: 266px;
  background-color: #c9171f !important;
  border-radius: 10px 0px 0px 10px;
}
#a4e65320 {
  width: 36px;
  padding-left: 116px;
  padding-bottom: 23px;
}
#a4e65320 {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: PingFang SC;
}
#f7f75bb3 {
  width: 67px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#f7f75bb3 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a7cc19aa {
  width: 122px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#a7cc19aa {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#cf6eac47 {
  width: 122px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#cf6eac47 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#ae7b0d83 {
  width: 105px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#ae7b0d83 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a85ff8c7 {
  width: 104px;
  margin-top: 30px;
  padding-left: 80px !important;
  padding-bottom: 30px;
}
#a85ff8c7 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#aff39595 {
  width: 266px;
  margin-top: 10px;
  margin-left: 3px;
}
#aff39595 .el-menu {
  margin-bottom: 30px;
  background-color: #f6f8fc !important;
  border-radius: 10px 0px 0px 10px;
}
#ad0ac297 {
  width: 266px;
}
#ad0ac297 {
  background-color: rgb(201, 23, 31, 1);
  border-radius: 10px 0px 10px 0px;
}
#aff39595 .el-submenu__title {
  width: 266px;
  background-color: #c9171f !important;
  border-radius: 10px 0px 0px 10px;
}
#f6a6d553 {
  width: 36px;
  padding-left: 116px;
  padding-bottom: 23px;
}
#f6a6d553 {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: PingFang SC;
}
#c0a99338 {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
  padding-bottom: 30px;
}
#c0a99338 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#ba25a2f3 {
  width: 266px;
  margin-top: 10px;
  margin-left: 3px;
}
#ba25a2f3 .el-menu {
  margin-bottom: 30px;
  background-color: #f6f8fc !important;
  border-radius: 10px 0px 0px 10px;
}
#ccbb31b2 {
  width: 266px;
}
#ccbb31b2 {
  background-color: rgb(201, 23, 31, 1);
  border-radius: 10px 0px 10px 0px;
}
#ba25a2f3 .el-submenu__title {
  width: 266px;
  background-color: #c9171f !important;
  border-radius: 10px 0px 0px 10px;
}
#aa3de0f6 {
  width: 72px;
  padding-left: 98px;
  padding-bottom: 23px;
}
#aa3de0f6 {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: PingFang SC;
}
#feabdb07 {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#feabdb07 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a4577423 {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#a4577423 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a7923738 {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#a7923738 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#ae69b77d {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#ae69b77d {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#acda3625 {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#acda3625 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a127529f {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#a127529f {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#ada52486 {
  width: 115px;
  margin-top: 30px;
  padding-left: 80px !important;
  padding-bottom: 30px;
}
#ada52486 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a5e49c58 {
  width: 266px;
  margin-top: 10px;
  margin-left: 3px;
}
#a5e49c58 .el-menu {
  margin-bottom: 30px;
  background-color: #f6f8fc !important;
  border-radius: 10px 0px 0px 10px;
}
#a3870799 {
  width: 266px;
}
#a3870799 {
  background-color: rgb(201, 23, 31, 1);
  border-radius: 10px 0px 10px 0px;
}
#a5e49c58 .el-submenu__title {
  width: 266px;
  background-color: #c9171f !important;
  border-radius: 10px 0px 0px 10px;
}
#a7b990c0 {
  width: 72px;
  padding-left: 98px;
  padding-bottom: 23px;
}
#a7b990c0 {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: PingFang SC;
}
#b45e0204 {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#b45e0204 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#cfe4dc7b {
  width: 115px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#cfe4dc7b {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a902d532 {
  width: 115px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#a902d532 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#afe1609d {
  width: 115px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#afe1609d {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#f367ca89 {
  width: 115px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#f367ca89 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#de9fac9c {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
  padding-bottom: 30px;
}
#de9fac9c {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#afc93192 {
  width: 266px;
  margin-top: 10px;
  margin-left: 3px;
}
#afc93192 .el-menu {
  margin-bottom: 30px;
  background-color: #f6f8fc !important;
  border-radius: 10px 0px 0px 10px;
}
#aff448f6 {
  width: 266px;
}
#aff448f6 {
  background-color: rgb(201, 23, 31, 1);
  border-radius: 10px 0px 10px 0px;
}
#afc93192 .el-submenu__title {
  width: 266px;
  background-color: #c9171f !important;
  border-radius: 10px 0px 0px 10px;
}
#adc88171 {
  width: 72px;
  padding-left: 98px;
  padding-bottom: 23px;
}
#adc88171 {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: PingFang SC;
}
#a2951665 {
  width: 115px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#a2951665 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a99c0001 {
  width: 115px;
  margin-top: 30px;
  padding-left: 80px !important;
  padding-bottom: 30px;
}
#a99c0001 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a65db403 {
  width: 266px;
  margin-top: 10px;
  margin-left: 3px;
}
#a65db403 .el-menu {
  margin-bottom: 30px;
  background-color: #f6f8fc !important;
  border-radius: 10px 0px 0px 10px;
}
#fa7c6235 {
  width: 266px;
}
#fa7c6235 {
  background-color: rgb(201, 23, 31, 1);
  border-radius: 10px 0px 10px 0px;
}
#a65db403 .el-submenu__title {
  width: 266px;
  background-color: #c9171f !important;
  border-radius: 10px 0px 0px 10px;
}
#a6a37c52 {
  width: 72px;
  padding-left: 98px;
  padding-bottom: 23px;
}
#a6a37c52 {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: PingFang SC;
}
#ccfb1508 {
  width: 115px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#ccfb1508 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#b4d6c792 {
  width: 115px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#b4d6c792 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#ce5e0416 {
  width: 115px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#ce5e0416 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#aa9ad6e7 {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
  padding-bottom: 30px;
}
#aa9ad6e7 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#ad1fb27f {
  width: 266px;
  margin-top: 10px;
  margin-left: 3px;
}
#ad1fb27f .el-menu {
  margin-bottom: 30px;
  background-color: #f6f8fc !important;
  border-radius: 10px 0px 0px 10px;
}
#a891505f {
  width: 266px;
}
#a891505f {
  background-color: rgb(201, 23, 31, 1);
  border-radius: 10px 0px 10px 0px;
}
#ad1fb27f .el-submenu__title {
  width: 266px;
  background-color: #c9171f !important;
  border-radius: 10px 0px 0px 10px;
}
#be50ddc9 {
  width: 90px;
  padding-left: 89px;
  padding-bottom: 23px;
}
#be50ddc9 {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: PingFang SC;
}
#c53bfec6 {
  width: 115px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#c53bfec6 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#ce152895 {
  width: 115px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#ce152895 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a414a716 {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
  padding-bottom: 30px;
}
#a414a716 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#abc8dc56 {
  width: 266px;
  margin-top: 10px;
  margin-left: 3px;
  margin-bottom: 940px;
}
#abc8dc56 .el-menu {
  margin-bottom: 30px;
  background-color: #f6f8fc !important;
  border-radius: 10px 0px 0px 10px;
}
#ae798753 {
  width: 266px;
}
#ae798753 {
  background-color: rgb(201, 23, 31, 1);
  border-radius: 10px 0px 10px 0px;
}
#abc8dc56 .el-submenu__title {
  width: 266px;
  background-color: #c9171f !important;
  border-radius: 10px 0px 0px 10px;
}
#f6039933 {
  width: 72px;
  padding-left: 98px;
  padding-bottom: 23px;
}
#f6039933 {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: PingFang SC;
}
#a831a061 {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#a831a061 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a7f45980 {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#a7f45980 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a89642ef {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#a89642ef {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#dc5457ac {
  width: 133px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#dc5457ac {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a71ae2eb {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#a71ae2eb {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#bccceec2 {
  width: 133px;
  margin-top: 30px;
  padding-left: 80px !important;
}
#bccceec2 {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
#a94534ec {
  width: 97px;
  margin-top: 30px;
  padding-left: 80px !important;
  padding-bottom: 30px;
}
#a94534ec {
  color: #141620;
  font-size: 18px;
  font-family: PingFang SC;
}
/*在此注释下方添加自定义样式*/
</style>
